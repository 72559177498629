@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,700;1,500&family=Oswald:wght@600&display=swap');

.container.is-fluid{
	margin:0;
}

body, html{
	text-rendering: optimizeLegibility !important;
	webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	font-family: 'Montserrat', sans-serif;	
	font-weight:500;
	width:100%;
	overflow-x:hidden;
	background:#000;
}

h1{
    color:#000;
    font-size:2.25rem;
    line-height:2.25rem;
    margin:0rem 0 1rem 0;
	font-family: 'Oswald', sans-serif;
	font-weight:700;
	text-transform: uppercase;
}

h2{
    font-size:1.5rem;
    line-height:1.5rem;
    margin-bottom:1rem;
	font-family: 'Oswald', sans-serif;
	font-weight:700;
	text-transform: uppercase;
	color:#000;
}


p {
    margin-bottom:1.25rem;
	font-size:1rem;
	color:#0f101f;
}


@media screen and (max-width:1280px){
	.h1{
		font-size:1.75rem;
	}
	h2{
		font-size:1.25rem;
	}
	p{
		font-size:1rem;
	}
}


img{
	max-width:100%;
	height:auto;
}

a{
	color:rgba(255,255,255,0.5)
}

.str-chat__date-separator {
	display: none;
}


.container-fluid{
    position:relative;
	overflow:hidden;
	max-width:1920px;
	margin:auto;
	width:100%;
}

.container--header {
	margin-top: 1.5rem;
}

.is-vertical-center {
    display: flex;
    align-items: center;
}

.no-pad{
	padding:0;
}

.center-align{
	text-align:center;
}

/**
SLIDE
**/

.slide{
	background:url(../assets/images/slide.jpg) top left no-repeat #e3e4e2;
	background-size:contain;
	padding:3rem 3rem;
	min-height:100vh;
}

.slide-archive {
	background:linear-gradient(45deg, #ffffff, #e3e4e2);
	background-size:contain;
	padding:3rem 3rem;
	min-height:100vh;
}

/**
CARD
**/

.card {
  border-radius: 8px;
  background: #FFFFFF;
  height: fit-content;
  margin-bottom: 2rem;
  margin-top: 2rem
}

.card .card-content .media {
  margin-bottom: 0;
}
@media screen and (max-width:1280px){
	.slide{
		padding:1rem;
	}
}

/**
LIVE LOGIN PANEL
**/

.live-panel{
	position:relative;
	overflow:hidden;
	border-radius:2rem;
	background:#f7f7f7;
	text-align:center;
	max-width:480px;
	margin:0px auto 2rem auto;
	box-shadow:-0.25rem 0.5rem 0.5rem rgba(0,0,0,0.15);
	padding-bottom:1rem;
}

.logo{
	padding:2.5rem 2rem;
	border-radius:2rem;
	text-align:center;
}

.logo img{
	max-width:180px;
	margin:auto;
	max-width: 180px;
	filter: invert();
}

.panel-content{
	max-width:350px;
	margin:auto;
	padding-bottom:2rem;
}

.panel-content input{
	width:100%;
	display:block;
	margin-bottom:1rem;
	padding:0.75rem 1rem;
	border-radius:2rem;
	border:2px solid #dedede;
	font-size:1rem;
	position:relative;
}

::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder ,:-moz-placeholder  { 
	color: rgba(0,0,0,0.85);
}

.access-code{
	position:relative;
}

.access-code:before{
	font-family: 'Font Awesome 5 Pro';
	font-weight:400;
  content: "\f084";
	position:absolute;
	right:1rem;
	top:0.6rem;
	color:#dedede;
	z-index:5;
	font-size:1rem;
}

.btn{
	background:#6ebeb5;
	color:#fff;
	text-transform: uppercase;
	font-family: 'Oswald', sans-serif;
	font-weight:700;
	font-size:1.25rem;
	border-radius:2rem;
	margin:0.25rem 0 0 0 ;
	width:100%;
	padding:1rem 0;
	box-shadow:0px;
	border:0;
}

.btn:hover{
	background:#000;
	cursor: pointer;
}

.btn:focus, input:focus{
	outline: none;
}

.play-archived-btn {
	background:#6ebeb5;
	color:#fff;
	text-transform: uppercase;
	font-family: 'Oswald', sans-serif;
	font-weight:500;
	border-radius:5rem;
  bottom: 1rem;
	width:2.2rem;
  height:2.2rem;
  padding: 1.5;
	box-shadow:0px;
	border:0;
}

.play-archived-btn:hover{
	background:#000;
	cursor: pointer;
}

#video-controls {
  display: flex;
  position: absolute;
  bottom: 10%;
  width: 100%;
}

.player-play-archived-btn {
  bottom: 10%;
  left: 5%;
	background:#6ebeb5;
	color:#fff;
	text-transform: uppercase;
	font-family: 'Oswald', sans-serif;
	font-weight:500;
	border-radius:5rem;
	width:2.2rem;
  height:2.2rem;
  padding: 1.5;
	box-shadow:0px;
	border:0;
  position: relative;
  margin-right: 5%;
}

.player-play-archived-btn:hover{
	background:#000;
	cursor: pointer;
}
.player-play-archived-btn:focus, input:focus{
	outline: none;
}

#seek-bar {
  position: relative;
  width: 82%;
  margin-left: 3%;
    margin-right: 3%;
}
#full-screen {
  border-width: unset;
  background-color: rgba(0, 0, 0, 0.5);
  border-color: unset;
  border-style: unset;
  color: white;
  outline: none;
  border-radius: 5px;
  bottom: 84%;
  width: 35px;
  height: 27px;
}

.right-controls {
  position: absolute;
  right: 31px;
  bottom: 28px;
}
#full-screen:hover{
	background:#000;
	cursor: pointer;
}
#mute {
  margin-right: 7px;
  border-width: unset;
  background-color: rgba(0, 0, 0, 0.5);
  border-color: unset;
  border-style: unset;
  color: white;
  border-radius: 5px;
  outline: none;
  width: 35px;
  height: 27px;
}
#mute:hover{
	background:#000;
	cursor: pointer;
}

.svg-inline--fa.fa-w-14 {
  /* width: 2.1em; */
  vertical-align: unset;
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type='range'] {
      overflow: hidden;
      cursor: pointer;
      -webkit-appearance: none;
      background-color: rgba(212, 211, 211, 0.5);
      height: 6px;
      margin-top: 15px;
      border-radius: 10px;
    }
    
    input[type='range']::-webkit-slider-runnable-track {
      height: 10px;
      -webkit-appearance: none;
      color: #13bba4;
      height: 10px;
      margin-top: -1px;
    }
    
    input[type='range']::-webkit-slider-thumb {
     background-color: white;
      width: 2px;
      -webkit-appearance: none;
      height: 10px;
      cursor: pointer;
      background: #ffffff;
      box-shadow: -3000px 0 0 3000px #ffffff;
    }

}
/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: #13bba4; 
}
input[type="range"]::-moz-range-track {  
  background-color: white;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: #13bba4; 
}
input[type="range"]::-ms-fill-upper {  
  background-color: white;
}

/** Archived View
**/

.archived-container .card-details {
  padding: unset;
}

.archived-container .card-details p {
  margin-bottom: 0.5rem;
}

.archived-container .card-details span {
  color: #adadad;
  font-size: 0.8em;
}

.unavailable-container {
  background: #FFFFFF;
  border-radius: 8px;
}

.archived-container .video{
	margin-bottom:1rem;
	margin-top:4.3rem;
	position:relative;
  border-radius: 8px;
}

.archived-container .video .video-timer {
  position: absolute;
  top: 30px;
  left: 20px;
  font-family: 'Oswald';
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5rem;
  color: #FFFFFF;
}

.archived-container video {
  border-radius: 8px;
  outline: none;
}

.play-archived-btn:focus, input:focus{
	outline: none;
}

.expand-button {
  background-color: unset;
  color: #FFFFFF;
  width: unset;
  padding: unset;
  font-size: unset;
  margin: unset;
  margin-left: 23%;
  margin-right: 3%;
  position: absolute;
}

.columns .card-container {
  margin-bottom: 2rem;
  border-radius: 8px;
  background: #FFFFFF;
  margin-left: unset;
  cursor: pointer;
}

.columns .card-container .is-4 {
  padding: unset;
  width: 28.33333%;
}

.columns .card-container .column {
  flex-basis: unset;
}

.columns .card-container img {
  padding: unset;
  border-radius: 8px 0px 0px 8px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.columns .is-one-third {
  margin-right: 3%;
  margin-left: 5%;
  height: 100vh;
}

.columns .is-one-third .video-list{
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 3rem;
  height: 100%;
  overflow: scroll;
}

.columns .is-one-third .title {
  font-size: 1.8rem;
  margin-bottom: 2.0rem;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Oswald';
}

.columns .card-container .card-details {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  margin-left: unset;
  width: 100%;
}

.columns .card-container .card-details p {
  font-size: 0.8rem;
  margin-right: 15%;
  margin-bottom: 0.5rem;
}

.columns .card-container .card-details span {
  color: #adadad;
  font-size: 0.8em;
}

.media-content p {
  margin-right: 4rem;
}

.media-content span {
  color: #b5b1b1;
}

input:focus{
	border-color:#6ebeb5;
}

.disabled-chat {
	padding-top: 5rem;
	overflow: scroll;
    height: 100%;
}

/**
POWERED BY
**/

.powered-by{
	width:auto;
	margin:auto;
	text-align:center;
	color:#000;
}

.powered-by span, .powered-by a{
	display:block;
	color:#000;
	font-size:0.75rem;
}

.powered-by img{
	display:inline-block;
}

.absolute-power{
	position:absolute;
	bottom:0;
	left:0;
	right:0;
}

.absolute-power a{
	display:inline-block;
}

.powered-by span, .powered-by a{
	color:#ccc;
}

.no-pad-left{
	padding-left:0;
}

.no-pad-left span{
	top:-5px;
	position:relative;
}

hr{
	background-color:rgba(255,255,255,0.1);
}

/**
FORM ERROR
**/

.form-line{
	position:relative;
}

.form-error-message{
	margin-bottom:1rem;
	font-size:0.65rem;
	color:#ff4948;
	position:absolute;
	right:1rem;
	top:2.85rem;
}
.form-error-message img{
	display:none;
}

.form-line-error input{
	border-color:#ff4948;
}

@media screen and (max-width:1060px){
	.slide{
		background-size:cover;
	}
	.panel-content{
		padding:0 1.5rem 2rem;
	}
}

@media screen and (max-width:768px){
  .disabled-chat { padding-top: 5rem; }
	.slide{
		background:#e3e4e2;
	}
}

@media screen and (max-width:525px){
	.live-panel{
		box-shadow:none;
	}
}

@media screen and (max-width:480px){
	.slide{
		padding:0;
		background:#f7f7f7;
		min-height:auto;
	}
	.hidden-xs{
		display:none;
	}
	.live-panel, .logo{
		border-radius:0px;
	}
}

/**
STREAM PAGE
**/

.stream-page{
	padding:0rem 1rem;
	height: 100vh;
}

.stream-page h1{
	margin:0 0 1rem 0;
	color:#fff;
	text-transform: uppercase;
}

.stream-page p{
	color:#ccc;
	font-size: 1em;
}

.video{
	margin-bottom:1rem;
	margin-top:1rem;
	position:relative;
}

.live{
	background:#ff4948;
	color:#fff;
	padding: 0.1rem 0.5rem;
	text-transform: uppercase;
	position:absolute;
	z-index:5;
	top:0.5rem;
	left:0.5rem;
	font-size:0.75rem;
	border-radius: 0.4rem;
}
.offline{
	background:#ccc;
	color:#fff;
	padding: 0.1rem 0.5rem;
	text-transform: uppercase;
	position:absolute;
	z-index:5;
	top:0.5rem;
	left:0.5rem;
	font-size:0.75rem;
	border-radius: 0.4rem;
	display: none;
}

iframe{
	width:100%;
	height:100%;
	min-height:500px;
}

.live-questions{
	position:relative;
	height:100vh;
	overflow:hidden;
	padding:0 1rem 0 0;
}

.live-questions h2{
	color:#fff;
	border-radius:2rem;
	background:#474747;
	padding:0.5rem;
	font-size: 1rem;
	border:5px solid #000;
	margin-bottom: 0;
}

.visible-xs{
	display:none;
}
.hidden-xs{
	display:block;
}

.str-chat__small-message-input-emojiselect, .str-chat__small-message-input-fileupload {
	display: none;
}

.str-chat__message-livestream-content{
	background:rgba(0, 0, 0, 0.5);
	border-radius:0.5rem;
}

.str-chat__small-message-input__wrapper {
    position: absolute;
    z-index: 99;
    width: 100%;
    bottom: 0;
    padding: 0;
}

.str-chat__reverse-infinite-scroll {
	margin-bottom: 15rem;
}

.rfu-dropzone {
	background-color: #1c1c1c;
}

.str-chat__message-livestream-right{
	padding-left:1rem;
}

.str-chat__message-livestream-author {
	margin-bottom:0;
  color: 'white';
}

.str-chat__message-livestream-author strong {
  color: white;
}

.str-chat__message-livestream-content p{
	color:'white';
	font-size:0.85rem;
}

.str-chat__avatar--circle{
	border-radius:0.25rem;
}

.dark.str-chat .str-chat__small-message-input textarea{
	background:#bbbbbb;
	color:#000;
}

.str-chat__li--top, .str-chat__li--single, .str-chat__li--bottom{
	margin:0 0 0 0;
}

.str-chat__message-livestream{
	padding:0.5rem 0;
}
.dark.str-chat .str-chat__simple-reactions-list-item--last-number{
	color:#000;
}

.dark.str-chat .str-chat__simple-reactions-list{
	border:1px solid rgba(0,0,0,0.25);
}

.str-chat *, .str-chat *::after, .str-chat *::before{
	font-family: 'Montserrat', sans-serif;
}

.str-chat a{
	margin-top:1rem;
}

.str-chat__avatar-fallback{
	background:#2FB7A7;
}

.str-chat.livestream.dark{
	background:none;
}

.livestream.str-chat .str-chat__list{
	padding: 1.5rem 0;
}

.str-chat-channel{
	max-height:100%;
}

.unavailable {
	width: 100%;
	min-height: 50vh;
	padding: 10%;
}

.chat-widget ul {
	list-style: unset;
	padding-left: 20px;
	color: #ccc;
}

.message-container {
  margin-top: 4px;
}
.message-container:hover .kick-out{
  display: unset;
}

.kick-out {
  display: none;
  background-color: rgba(0, 0, 0, 1);;
  border: 1px solid rgba(224, 224, 224, 0.03);
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07);
  position: absolute;
  right: 148px;
  top: -9px;
  font-size: 11px;
  font-family: 'Montserrat', sans-serif;
  z-index: 1;
  color: #fff;
  opacity: 0.5;
  cursor: pointer;
  padding: 0px 3px 0px 3px;
  border-radius: 24px;
}

.kick-out:hover {
  color: white;
  background: red;
  opacity: 1;
}

.str-chat__list-notifications {
  position: unset;
}
.str-chat__message-notification {
  bottom: 55px;
}

.str-chat__ul li {
  display: block;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: rgba(0,0,0,0.60);
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color: rgb(0,0,0);
   opacity:  0.6;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color: rgb(0,0,0);
   opacity:  0.6;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: rgba(0,0,0,0.60);;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color: rgba(0,0,0,0.60);;
}

::placeholder { /* Most modern browsers support this now. */
   color: rgba(0,0,0,0.60);
}

@media screen and (max-width:769px){
	p{
		font-size:0.8rem;
	}
	.stream-page{
		padding:0;
		height: 0;
	}
	.visible-xs{
		display:block;
	}
	.hidden-xs{
		display:none;
	}
	.absolute-power{
		position:relative;
	}
	.live-questions{
		border-radius:3% 3% 0 0;
		padding:0;
		background:#1c1c1c;
		position:absolute;
		bottom: 0;
		right: 0;
		left: 0;
    height: 50%;
    padding-bottom: 1rem;
	}
	.chat-widget{
		width:auto;
		margin: 1rem;
    margin-top: 0;
    height: 100%;
    padding-top: 1rem;
	}
	iframe{
		min-height:45vh;
	}
	.livestream.str-chat .str-chat__list{
    margin-top: 3rem;
		height: 100%;
    margin-bottom: 3rem;
	}
	.container-fluid {
		position: static;
	}
	.video {
		margin-top: 0;
		margin-bottom: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
    height: 50%;
	}

  .main-archived {
    display: flex;
    flex-direction: column-reverse;
  }

  .columns .card-container {
    display: flex;
  }

  .columns .card-container img {
    max-width: 82%;
  }
 
  .columns .card-container .card-details {
    margin-bottom: unset;
    margin-right: unset;
  }

  .columns .is-one-third .title {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  .slide-archive {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
  }

  .archived-container .video {
		margin-top: 0;
		margin-bottom: 0;
    height: 50%;
	}
  
  .archived-container video {
    border-radius: 8px;
  }

	.container--header {
		margin-top: 1rem;
		margin-left: 1rem;
		margin-right: 1rem;
		margin-bottom: 0;
	}

	.container--header h2 {
		margin-bottom: 0;
	}
	

	#playerElement-MediaController-ControlsLeft {
		bottom: 1rem !important;
	}

	#playerElement-MediaController-ControlsRight {
		bottom: 1rem !important;
	}

	.str-chat__small-message-input {
		margin: unset;
	}

	.str-chat__textarea {
		flex: 1 0;
	}

	.rta.str-chat__textarea {
		width: 97%;
		margin-left: 20px;
	}

	.livestream.dark.str-chat .str-chat__message-livestream {
		margin-left: 1rem;
		margin-right: 1rem;
	}

	.str-chat__message-livestream {
		width: auto;
	}
	.container--header {
		position: fixed;
		right: 0;
		left: 0;
	}
  .str-chat__send-button {
    position: absolute;
    right: 12px;
    top: 9px;
  }
  .str-chat__small-message-input__wrapper {
    padding-right: 7%;
    padding-bottom: 0.5rem;
	}
  .str-chat__reverse-infinite-scroll {
	  margin-bottom: 2rem;
  }
}

@media screen and (max-width:375px){
  .str-chat__small-message-input__wrapper {
    padding-right: 15%;
	}
  .str-chat__send-button {
    right: -5px;
  }
}